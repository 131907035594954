<template>
    <!-- <Timeline>
        <TimelineItem color="green">发布1.0版本</TimelineItem>
        <TimelineItem color="green">发布2.0版本</TimelineItem>
        <TimelineItem color="red">严重故障</TimelineItem>
        <TimelineItem color="blue">发布3.0版本</TimelineItem>
    </Timeline> -->
    <h2>尚未开工哦~</h2>
</template>
<script>
export default {
  name: 'ContentChanglog',
  components: {

  }
}
</script>
<style scoped>

</style>